/* carrois-gothic-sc-regular - latin */
@font-face {
  font-family: "Carrois Gothic SC";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/carrois-gothic-sc/carrois-gothic-sc-v10-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/carrois-gothic-sc/carrois-gothic-sc-v10-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/carrois-gothic-sc/carrois-gothic-sc-v10-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/assets/fonts/carrois-gothic-sc/carrois-gothic-sc-v10-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("/assets/fonts/carrois-gothic-sc/carrois-gothic-sc-v10-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/carrois-gothic-sc/carrois-gothic-sc-v10-latin-regular.svg#CarroisGothicSC")
      format("svg");
  /* Legacy iOS */
}

/* quicksand-regular - latin */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/quicksand/quicksand-v24-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/quicksand/quicksand-v24-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/quicksand/quicksand-v24-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/assets/fonts/quicksand/quicksand-v24-latin-regular.svg#Quicksand") format("svg");
  /* Legacy iOS */
}

/* quicksand-600 - latin */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/quicksand/quicksand-v24-latin-600.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/quicksand/quicksand-v24-latin-600.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/quicksand/quicksand-v24-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-600.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/quicksand/quicksand-v24-latin-600.svg#Quicksand")
      format("svg");
  /* Legacy iOS */
}

/* quicksand-500 - latin */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/quicksand/quicksand-v24-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/quicksand/quicksand-v24-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/quicksand/quicksand-v24-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-500.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/quicksand/quicksand-v24-latin-500.svg#Quicksand")
      format("svg");
  /* Legacy iOS */
}

/* quicksand-700 - latin */
@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/quicksand/quicksand-v24-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/quicksand/quicksand-v24-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/quicksand/quicksand-v24-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-700.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/quicksand/quicksand-v24-latin-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/quicksand/quicksand-v24-latin-700.svg#Quicksand")
      format("svg");
  /* Legacy iOS */
}

/* roboto-regular - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/roboto/roboto-v27-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/roboto/roboto-v27-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/roboto/roboto-v27-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/roboto/roboto-v27-latin-regular.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-500 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/roboto/roboto-v27-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/roboto/roboto-v27-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/roboto/roboto-v27-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/roboto/roboto-v27-latin-500.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-500italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: url("/assets/fonts/roboto/roboto-v27-latin-500italic.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/roboto/roboto-v27-latin-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/roboto/roboto-v27-latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/roboto/roboto-v27-latin-500italic.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-700 - latin */
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/roboto/roboto-v27-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/roboto/roboto-v27-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/roboto/roboto-v27-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/roboto/roboto-v27-latin-700.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* roboto-700italic - latin */
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/roboto/roboto-v27-latin-700italic.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/roboto/roboto-v27-latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/roboto/roboto-v27-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/roboto/roboto-v27-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/roboto/roboto-v27-latin-700italic.svg#Roboto")
      format("svg");
  /* Legacy iOS */
}

/* ubuntu-regular - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/ubuntu/ubuntu-v15-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/ubuntu/ubuntu-v15-latin-regular.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-regular.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-regular.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-regular.svg#Ubuntu")
      format("svg");
  /* Legacy iOS */
}

/* ubuntu-500 - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/ubuntu/ubuntu-v15-latin-500.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/ubuntu/ubuntu-v15-latin-500.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500.svg#Ubuntu")
      format("svg");
  /* Legacy iOS */
}

/* ubuntu-500italic - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 500;
  src: url("/assets/fonts/ubuntu/ubuntu-v15-latin-500italic.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/ubuntu/ubuntu-v15-latin-500italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500italic.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-500italic.svg#Ubuntu")
      format("svg");
  /* Legacy iOS */
}

/* ubuntu-700 - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/ubuntu/ubuntu-v15-latin-700.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/ubuntu/ubuntu-v15-latin-700.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700.woff") format("woff"),
    /* Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700.ttf") format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700.svg#Ubuntu")
      format("svg");
  /* Legacy iOS */
}

/* ubuntu-700italic - latin */
@font-face {
  font-family: "Ubuntu";
  font-style: italic;
  font-weight: 700;
  src: url("/assets/fonts/ubuntu/ubuntu-v15-latin-700italic.eot");
  /* IE9 Compat Modes */
  src: local(""),
    url("/assets/fonts/ubuntu/ubuntu-v15-latin-700italic.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700italic.woff2") format("woff2"),
    /* Super Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700italic.woff")
      format("woff"),
    /* Modern Browsers */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */ url("/assets/fonts/ubuntu/ubuntu-v15-latin-700italic.svg#Ubuntu")
      format("svg");
  /* Legacy iOS */
}
