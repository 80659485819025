@import "./fonts";
@import "./variables";

// normal text
@mixin paragraphText {
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  // color: $colorText;
}

@mixin scrollBar {
  ::-webkit-scrollbar {
    width: 6px;
    height: 30px;
  }

  ::-webkit-scrollbar-thumb {
    height: 6px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

@mixin scrollBar2 {
  &::-webkit-scrollbar {
    width: 6px;
    height: 30px;
  }

  &::-webkit-scrollbar-thumb {
    height: 6px;
    background-color: rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

// buttons
.alm-btn {
  background-color: transparent !important;
  color: black;

  span {
    color: black;
  }

  &:disabled {
    background-color: $colorDisabled !important;
    color: $colorDisabledTxt;

    span {
      color: $colorDisabledTxt;
    }
  }
}

.alm-btn-primary {
  background-color: $colorPrimary !important;
  color: white;

  span {
    color: white;
  }
}

.alm-btn-secondary {
  background-color: $colorSecondary !important;
  color: white;

  span {
    color: white;
  }

  &:disabled {
    background-color: $colorDisabled !important;
    color: $colorDisabledTxt;

    span {
      color: $colorDisabledTxt;
    }
  }
}

.alm-btn-icon-secondary {
  background-color: $colorSecondary !important;
  color: white;

  span {
    color: white;
  }

  &:disabled {
    background-color: $colorDisabled !important;
    color: $colorDisabledTxt;

    span {
      color: $colorDisabledTxt;
    }
  }

  &:hover:enabled {
    background-color: $colorPrimary !important;
  }
}

.alm-btn-disabled {
  background-color: $colorDisabled !important;
  color: $colorDisabledTxt;
}

.alm-btn-success {
  background-color: $colorPrimary !important;
  color: white;

  span {
    color: white;
  }

  &:disabled {
    background-color: $colorDisabled !important;
    color: $colorDisabledTxt;

    span {
      color: $colorDisabledTxt;
    }
  }

  &:hover:enabled {
    background-color: $colorSecondary !important;
  }
}

.alm-btn-outlined {
  background-color: transparent !important;
  border: 1px solid $colorPrimary !important;

  &:disabled {
    background-color: $colorDisabled !important;
    border: none !important;
    color: $colorDisabledTxt;

    span {
      color: $colorDisabledTxt;
    }
  }

  &:hover:enabled {
    background-color: $colorSecondary !important;
    color: white;
  }
}

.alm-btn-danger {
  background-color: $colorDanger !important;
  color: white;

  span {
    color: white;
  }

  &:disabled {
    background-color: $colorDisabled !important;
    color: $colorDisabledTxt;

    span {
      color: $colorDisabledTxt;
    }
  }
}

.alm-btn-cancel {
  background-color: #404969 !important;
  color: white;

  span {
    color: white;
  }

  &:disabled {
    background-color: $colorDisabled !important;
    color: $colorDisabled;

    span {
      color: $colorDisabled;
    }
  }
}

// texts
.alm-color-text-error {
  color: $colorDanger !important;
}

.alm-color-text-primary {
  color: $colorPrimary !important;
}

.alm-color-text-success {
  color: green !important;
}

.alm-color-text-disabled {
  color: $colorDisabledTxt;
}

.alm-color-bg-disabled {
  background: rgba(0, 0, 0, 0.38);
}

button {
  outline: none !important;
}

.dark-theme {
  button {
    &:disabled {
      background-color: $colorDarkBtnDisabledBg !important;
      color: $colorDarkBtnDisabledFont;

      span {
        color: $colorDarkBtnDisabledFont;
      }
    }
  }
  .alm-btn {
    color: white;
    span {
      color: white;
    }
  }
}

.a11y-btn-style {
  font-size: 19px !important;
}
