@import "./fonts";

// fonts
$fontRoboto: "Roboto", sans-serif;
$fontCarrois: "Carrois Gothic SC", sans-serif;
$fontQuicksand: "Quicksand", sans-serif;
$fontUbuntu: "Ubuntu", sans-serif;

:root {
  --color-primary: #f26322;
  --color-brand: #f26322;
}

// colors light
$colorBrand: var(--color-brand);
$colorPrimary: var(--color-primary);
$colorSecondary: #404969;
$colorTertiary: #dadada;
$colorDarkBlue: #0d2d54;
$colorDanger: #db4437;
$colorDisabledTxt: rgba(0, 0, 0, 0.4);
$colorDisabled: rgba(0, 0, 0, 0.12);
$colorDisabledDarker: #797879;
$colorBg: #f3f5fb;
$colorBorder: 1px solid rgba(0, 0, 0, 0.12);
$colorBorderDark: #b9b9b9;
$colorText: rgba(0, 0, 0, 0.85);
$colorError: tomato;
$colorWarning: #f8bb86;
$colorConnecting: #fdcb6e;
$colorOnline: #5a8c00;
$colorSuccess: #466e00;
$colorLoginBg: #c5ebff;
$colorBgIcon: #e4f6ff;
$colorTextGrey: #6d6d6d;
$colorBgLightBlue: #e2f5ff;
$colorBgGrey: #f3f4f7;
$colorBgOrange: #f9f7f6;
$colorBgBlue: #e2f5ff;
$colorHover: #f2f2f2;
$colorBackground: #f8f8f8;
$colorTextDefault: #1d252f;
$colorTextDarkBlue: #6eaccd;
$colorSelectedMenu: #f3f3f3;
$colorContract: #6eaccd;
$colorPrivate: #a85eb4;
$colorHomeViewerBackground: #f3f3f3;
$colorGreyBackground: #f3f3f3;
$colorMiddleBlue: #7dc4e9;
$coloreAUBg: #f0f4de;
$colorDarkGrey: #4a4a49;
$colorBgAUBlueDefault: #e0f2fc;
$colorVideoBg: #2c2c2c;
$colorLightGreyBg: #e5e5e5;
$colorLightOrangeBg: rgba(242, 99, 34, 0.3);
$colorWhiteBg: rgba(255, 255, 255, 0.8);
$colorLightBlackBg: rgba(0, 0, 0, 0.6);
$colorLightGreyText: #c4c4c4;
$colorTextLink: #006adb;
$colorMatLabel: rgba(0, 0, 0, 0.9);

$minWidthSideDrawer: 340px;

$borderRadius: 7px;
$borderRadiusSelectedMenu: 14px;

$defaultShadow: 0px 7px 10px rgb(0 0 0 / 10%);

// Colors dark theme
$colorDarkBackground1: #2f2f2f;
$colorDarkBackground2: #535353;
$colorDarkBackground3: #b4b4b4;
$colorDarkBackground4: #404040;
$colorDarkBackground5: #454545;
$colorDarkBackground6: #303030;
$colorDarkText1: #444444;
$colorDarkErrorText1: #ff8e38;
$colorDarkLinkText1: #66b0ff;
$colorDarkBtnDisabledBg: #555555;
$colorDarkBtnDisabledFont: #999999;
$colorDarkIcon: #2f2f2f;
$darkShadow: 0px 4px 10px 4px #4c4c4c;
