$material-icons-font-path: "~material-icons/iconfont/";
$material-icons-font-path: "./" !default;
$material-icons-font-size: 24px !default;
$material-icons-font-display: block !default;
@import "~material-icons/iconfont/material-icons.scss";
@import "~bootstrap/scss/bootstrap";
@import "./assets/styles/scss/variables";
@import "./assets/styles/scss/alm-styles.scss";

html,
body {
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  // background-color: $colorBg;
  background-color: white;
}

body {
  margin: 0;
  font-family: $fontRoboto !important;
  overflow: hidden;
}

app-root {
  p,
  label {
    @include paragraphText;
    color: $colorTextDefault;
  }

  a {
    @include paragraphText;
    color: $colorTextLink;
  }
}

app-root {
  .dark-theme {
    p,
    label {
      color: white;
    }

    form-select {
      svg {
        path {
          fill: white;
        }
      }
    }

    a {
      color: $colorDarkLinkText1;
    }
    
    mat-icon {
      color: white;
    }
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: $colorTextDefault;
}

.loader {
  cursor: progress;
}

app-root {
  min-height: 100% !important;
  height: 100%;
  max-height: 100%;
  display: block;
}

.returnDesc {
  a {
    text-decoration: underline !important;
    font-weight: 500 !important;
    cursor: pointer;
  }
}

// disable outline mat-menu
.mat-menu-item {
  &:focus {
    outline: none !important;
  }
}

button {
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  text-align: center !important;
  letter-spacing: 0.0357em;
  border-radius: 5px !important;
  text-transform: uppercase !important;
  padding-left: 15px;
  padding-right: 15px;
}

.mat-icon-button {
  border-radius: 50% !important;
  color: #404969;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

button[mat-raised-button] {
  outline: none !important;
}

button[mat-icon-button] {
  outline: none !important;
}

.cusScrollBar {
  @include scrollBar2;
}

.mat-snack-bar-handset {
  max-width: 397px !important;
}

.mat-checkbox-inner-container {
  margin-top: 4px !important;
}

mat-chip {
  background: antiquewhite !important;
  width: fit-content;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  position: relative;
  font-weight: 400 !important;

  mat-icon {
    cursor: pointer;
    width: 18px !important;
    height: 18px !important;
    margin-left: 10px;
  }

  .mat-chip-ripple {
    display: none;
  }
}

//mat-label is made bold and large for a11y reasons
mat-label {
  font-weight: bold;
  font-size: large;
  color: $colorMatLabel;
}

.externalLinkImg {
  font-size: 12px;
  margin-bottom: 0px;
  height: 12px;
  width: 12px;
}

// @media using max-width is normal, start with smallest

// Extra small devices (portrait phones, less than 576px)
// No media query for `xs` since this is the default in Bootstrap

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
}

// Large devices (desktops, 1400px and up)
@media (min-width: 1400px) {
}

@media (min-width: 1900px) {
}

// @media using max-width is reverse, start with biggest

// Large devices (desktops, less than 1200px)
@media (max-width: 1199.98px) {
}

// Medium devices (tablets, less than 992px)
@media (max-width: 991.98px) {
}

// Small devices (landscape phones, less than 768px)
@media screen and (max-width: 767.98px), screen and (max-height: 600px) {
  // Remove mobile auto Zoom while adding input
  input[type="text"],
  input[type="email"],
  input[type="password"] {
    font-size: 16px;
  }
}

// Extra small devices (portrait phones, less than 576px)
@media (max-width: 575.98px) {
}

// Extra small devices (portrait phones, less than 350px)
@media (max-width: 350px) {
}

.dark-theme {
  mat-label {
    color: white;
  }
}
